import {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Divider, List} from "antd-mobile";
import Network from "./network";

const QuestionnaireResults1: FC = () => {
    const location = useLocation();
    const content = location.state["content"]
    const navigate = useNavigate()

    // const onFinish = () => {
    //
    // }

    const onRetry = async ()=>{
        const response = await Network.getInstance().get(`/investigation/problem_plan_configuration_info?type=1&score=0`)
        if (response.data["questionOptions"]) {
            response.data["questionOptions"] = JSON.parse(response.data["questionOptions"])
            navigate("/question", {state: response.data})
            return
        }
    }

    return <>
        <List mode='card' header={location.state["label"]}>
            {
                // @ts-ignore
                content.map(function (element, index) {
                    return <List.Item description={element["value"]} key={`list${index}`}>
                        {element["key"]}
                    </List.Item>
                })
            }
        </List>
        <div style={{padding:"2em"}}>
        {/*<Divider/>*/}
        {/*<Button block color='primary' size='large' onClick={onFinish}>训练指导</Button>*/}
        <Divider/>
        <Button block color='primary' size='large' onClick={onRetry}>重新测试</Button>
        </div>
    </>
}

export default QuestionnaireResults1;