import {Toast} from "antd-mobile";
import axios, {AxiosError} from "axios";
import {AxiosInstance} from "axios";

class Network {

    private static instance: AxiosInstance;

    public static getInstance() {
        if (!this.instance) {
            this.instance = axios.create(
                {
                    baseURL: 'http://opt.jingjingle.cn/appapi/',
                    // baseURL: 'http://192.168.0.102:7004/'
                }
            );
            this.instance.interceptors.response.use(
                function (response) {
                    if (typeof response.data === "string") {
                        response.data = JSON.parse(response.data)
                    }
                    if (!response.data) {
                        Toast.show({
                            icon: 'fail',
                            content: '数据异常',
                        });
                        return Promise.reject(new AxiosError("数据异常"))
                    }
                    if (response.data["code"] !== 0) {
                        Toast.show({
                            icon: 'fail',
                            content: `服务器异常:${response.data["msg"]}`,
                        });
                        return Promise.reject(new AxiosError(`服务器异常:${response.data["msg"] ?? "网络异常"}`))
                    }
                    response.data = response.data["data"]
                    return response;
                }, function (error) {
                    console.log(JSON.stringify(error));
                    Toast.show({
                        icon: 'fail',
                        content: '网络异常，请检查网络连接',
                    });
                    return Promise.reject(error);
                }
            )
        }

        return this.instance;
    }

}

export default Network;