import SafeArea from 'antd-mobile/es/components/safe-area';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import Amblyopia from "./amblyopia";
import QuestionnaireResults from './questionnaireResults';
import QuestionnaireResults1 from "./questionnaireResults1";
import Err from "./err";
import Load from './load';
import ErrVer from "./errver";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Load/>,
    },
    {
        path: "/question",
        element: <Amblyopia/>
    },
    {
        path: "/next",
        element: <Amblyopia/>,
    },
    {
        path: "/result",
        element: <QuestionnaireResults/>,
    },
    {
        path: "/result1",
        element: <QuestionnaireResults1/>,
    },
    {
        path: "/err",
        element: <Err/>,
    },
    {
        path: "/errVer",
        element: <ErrVer/>,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <SafeArea position={"top"}/>
        <RouterProvider router={router}/>
        <SafeArea position={"bottom"}/>
    </React.StrictMode>
);

