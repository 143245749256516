import {FC, RefObject} from "react";
import dayjs from 'dayjs'
import {
    Button,
    Checkbox,
    DatePicker,
    DatePickerRef,
    Form,
    Input,
    Radio,
    Selector,
    Slider,
    Space,
    Stepper,
    Switch,
    Toast
} from "antd-mobile";
import {DatePrecision} from "antd-mobile/es/components/date-picker/date-picker-date-utils";
import {WeekPrecision} from "antd-mobile/es/components/date-picker/date-picker-week-utils";
import { useLocation, useNavigate} from "react-router-dom";
import Network from "./network";

const Amblyopia: FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const location = useLocation()
    let score = 0;

    const jsonData = location.state
    const id = jsonData["id"]
    // @ts-ignore
    const submit = jsonData["questionOptions"]["submit"]
    // @ts-ignore
    const question = jsonData["questionOptions"]["question"]
    // @ts-ignore
    const result = jsonData["questionOptions"]["result"]

    const onFinish = async (values: any) => {
        // @ts-ignore
        score = jsonData["score"] ?? 0

        if(score<0){
            score = 0
        }
        // @ts-ignore
        // const resultData = jsonData.find(function (element) {
        //     return element["type"] === "result"
        // })
        // const parameter = resultData!["result"]!
        const parameter = result!["result"]!

        const postData = {"answer": []};
        Object.entries(values).forEach(([k, v]) => {
            // @ts-ignore
            const f = question.find(function (d) {
                return d["key"] === k;
            })
            if (f) {
                if (f["marks"]) {
                    if (typeof v === 'number') {
                        // @ts-ignore
                        postData["answer"].push({"key": f["title"], "value": f["marks"][v]})
                    }
                } else {
                    if (f["options"]) {
                        const vvv = v as Array<any>
                        if (typeof v === "string" || vvv.length === 1) {
                            // @ts-ignore
                            const vv = f["options"]!.find(function (o) {
                                return o["value"] === v || o["value"] === vvv[0];
                            })
                            if (vv) {
                                // @ts-ignore
                                postData["answer"].push({"key": f["title"], "value": vv["title"]})
                                if (vv["score"]) {
                                    score += vv["score"];
                                }
                            }
                        } else {
                            const vResult = new Array<string>();
                            vvv.forEach(function (vElement) {
                                // @ts-ignore
                                const vv = f["options"]!.find(function (o) {
                                    return o["value"] === vElement;
                                })
                                if (vv) {
                                    vResult.push(vv["title"]);
                                    if (vv["score"]) {
                                        score += vv["score"];
                                    }
                                }
                            })
                            // @ts-ignore
                            postData["answer"].push({"key": f["title"], "value": vResult.join(",")})
                        }

                    } else {
                        // @ts-ignore
                        postData["answer"].push({"key": f["title"], "value": v})
                    }
                }
            }
        })
        console.log(`返回数据：${JSON.stringify(postData)}`)
        let stateData = {};
        // @ts-ignore
        parameter.forEach(function (element) {
            if (result!["toView"] === "/result") {
                if (score >= element["score"]) {
                    // @ts-ignore
                    postData["ver"] = 1
                    // @ts-ignore
                    postData["result"] = {
                        ...element,
                    }
                    stateData = {
                        ...element,
                    }
                }
            } else {
                if (score === element["score"]) {
                    // @ts-ignore
                    postData["ver"] = 2
                    // @ts-ignore
                    postData["result"] = {
                        ...element,
                    }
                    stateData = {
                        ...element,
                    }
                }
            }
        })
        // @ts-ignore
        if (!stateData["score"]) {
            Toast.show({icon: "fail", content: "未配置方案"})
            console.log(`分数:${score}`)
            return
        }
        // @ts-ignore
        stateData["score"] = score
        // @ts-ignore
        postData["score"] = score
        if (result["label"]) {
            // @ts-ignore
            stateData["label"] = result["label"]
            // @ts-ignore
            postData["label"] = result["label"]
        }
        // @ts-ignore
        stateData["url"] = result["url"]
        // @ts-ignore
        postData["url"] = result["url"]
        // @ts-ignore
        postData["toView"] = result["toView"]

        await Network.getInstance().post("/investigation/add_uid_problem_plan_result", {"problemPlanConfigurationId":id,"resultDetails":JSON.stringify(postData)})

        navigate(result!["toView"]!, {
            replace: true,
            state: stateData,
        });
    }

    return <>
        <Form
            layout='vertical'
            mode='card'
            form={form}
            onFinish={onFinish}
            footer={<>
                {
                    submit ? <Button block type='submit' color='primary' size='large' key="submitButton">
                        {submit["value"]}
                    </Button> : null
                }
            </>}>
            {
                // @ts-ignore
                question.map(function (item, index, array) {
                    switch (item["type"]) {
                        case "title":
                            return <Form.Header key={item["type"] + index}>{item["value"]}</Form.Header>;
                        case "input":
                            return <Form.Item name={item["key"]} label={item["title"]}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}
                                              key={item["type"] + index}>
                                <Input placeholder={item["placeholder"]} key={item["type"] + "child" + index}/>
                            </Form.Item>
                        case "radio":
                            return <Form.Item name={item["key"]} label={item["title"]}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}
                                              key={item["type"] + index}>
                                <Radio.Group key={item["type"] + "group" + index}>
                                    <Space direction={"vertical"} key={item["type"] + "groupspace" + index}>
                                        {
                                            (item["options"] as Array<any>).map(function (child, childIndex, childArray) {
                                                return <Radio key={item["type"] + "groupradio" + childIndex}
                                                              value={child["value"]}>
                                                    {child["title"]}
                                                </Radio>
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>;
                        case "stepper":
                            return <Form.Item name={item["key"]} label={item["title"]} key={item["type"] + index}
                                              rules={[{required: item["required"]}]} childElementPosition='right'>
                                <Stepper min={item["min"]} max={item["max"]} value={item["min"]}
                                         key={item["type"] + "child" + index}/>
                            </Form.Item>;
                        case "switch":
                            return <Form.Item name={item["key"]} label={item["title"]} key={item["type"] + index}
                                              rules={[{required: item["required"]}]} childElementPosition='right'
                                              initialValue={false}>
                                <Switch
                                    key={item["type"] + "child" + index}/>
                            </Form.Item>;
                        case "datePicker":
                            return <Form.Item name={item["key"]} label={item["title"]} key={item["type"] + index}
                                              trigger='onConfirm'
                                              onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                                                  datePickerRef.current?.open()
                                              }}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}>
                                <DatePicker key={item["type"] + "child" + index}
                                            min={new Date((new Date()).getFullYear() - item["reduceYear"]!, 0, 0)}
                                            max={new Date((new Date()).getFullYear() + item["reduceYear"]!, 0, 0)}
                                            precision={item["precision"] as DatePrecision | WeekPrecision}>
                                    {value =>
                                        value ? dayjs(value).format(item["dataFormat"]) : item["placeholder"]
                                    }
                                </DatePicker>

                            </Form.Item>;
                        case "selector":
                            return <Form.Item name={item["key"]} label={item["title"]} key={item["type"] + index}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}>
                                <Selector key={item["type"] + "child" + index} multiple={item["multiple"]} options={
                                    (item["options"] as Array<any>).map(function (child, childIndex, childArray) {
                                        return {
                                            label: child["title"], value: child["value"]
                                        }
                                    })
                                }/>
                            </Form.Item>;
                        case "slider":
                            return <Form.Item name={item["key"]} label={item["title"]} key={item["type"] + index}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}>
                                <Slider key={item["type"] + "child" + index} ticks marks={item["marks"]}/>
                            </Form.Item>;
                        case "checkbox":
                            return <Form.Item name={item["key"]} label={item["title"]}
                                              rules={[{required: item["required"], message: item["requiredMsg"]}]}
                                              key={item["type"] + index}>
                                <Checkbox.Group key={item["type"] + "group" + index}>
                                    <Space direction={"vertical"} key={item["type"] + "groupspace" + index}>
                                        {
                                            (item["options"] as Array<any>).map(function (child, childIndex, childArray) {
                                                return <Checkbox key={item["type"] + "groupradio" + childIndex}
                                                                 value={child["value"]}>
                                                    {child["title"]}
                                                </Checkbox>
                                            })
                                        }
                                    </Space>
                                </Checkbox.Group>
                            </Form.Item>;
                        default:
                            return <div key={item["type"] + index}></div>;
                    }
                    // @ts-ignore
                }).filter(function (value) {
                    return value !== null
                })
            }
        </Form>
    </>;
}

export default Amblyopia;