import {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Divider, Result, Toast} from "antd-mobile";
import {SmileOutline} from "antd-mobile-icons";
import Network from "./network";

const QuestionnaireResults: FC = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const onFinish = async () => {
        const response = await Network.getInstance().get(`/investigation/problem_plan_configuration_info?type=2&score=${location.state["score"]}`)
        if (!response?.data["questionOptions"]) {
            Toast.show(`方案不存在`)
            return
        }
        response.data["questionOptions"] = JSON.parse(response.data["questionOptions"])
        response.data["score"] = location.state["score"]
        navigate("/next", {state: response.data})
    }

    return <>
        <Result icon={<SmileOutline/>} title={location.state["label"] + location.state["content"]}
                description="结果仅供参考"/>
        <Divider
        >
            睛晶乐已为您制定对应的训练方案
        </Divider>

        <div style={{padding: '8px'}}>
            <Button block color='primary' size='large' onClick={onFinish}> 立即查看训练方案</Button>
        </div>
    </>
}

export default QuestionnaireResults;