import Network from "./network";
import {FC, useEffect} from "react";
import {Result} from "antd-mobile";
import {useNavigate} from "react-router-dom";

const Load: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {

        new Promise(async function () {
            try {
                // @ts-ignore
                window.setVer = function (ver){
                    if (!ver || Number.parseInt(ver) <= 0) {
                        navigate("/errVer")
                        return
                    }
                }
                // @ts-ignore
                Ver.postMessage("");

            } catch (e) {
                console.log("版本太低");
                navigate("/errVer")
                return
            }

            try {
                // @ts-ignore
                window.setToken = async function (token)  {
                    if (!token) {
                        navigate("/err")
                    }

                    Network.getInstance().defaults.headers["Authorization"] = token;
                    const logResponse = await Network.getInstance().get("/investigation/newest_problem_plan_result")
                    if (logResponse.data) {
                        if (!logResponse.data["resultDetails"]) {
                            navigate("/err")
                            return
                        }
                        const resultDetails = JSON.parse(logResponse.data["resultDetails"])
                        switch (resultDetails["ver"]) {
                            case 1:
                                navigate(
                                    resultDetails["toView"], {
                                        state: {
                                            ...resultDetails["result"],
                                            "score": resultDetails["score"],
                                            "label": resultDetails["label"],
                                            "url": resultDetails["url"],
                                        }
                                    })
                                return
                            case 2:
                                navigate(
                                    resultDetails["toView"], {
                                        state: {
                                            ...resultDetails["result"],
                                            "score": resultDetails["score"],
                                            "label": resultDetails["label"],
                                            "url": resultDetails["url"],
                                        }
                                    })
                                return
                            default:
                                navigate("/err")
                                break
                        }
                    }

                    const response = await Network.getInstance().get(`/investigation/problem_plan_configuration_info?type=1&score=0`)
                    if (response.data["questionOptions"]) {
                        response.data["questionOptions"] = JSON.parse(response.data["questionOptions"])
                        navigate("/question", {state: response.data})
                        return
                    }
                    navigate("/err")
                }
                // @ts-ignore
                Token.postMessage("");

                // const token = "eyJhbGciOiJIUzUxMiJ9.eyJhcHBfdXNlcl9pZCI6MTYsInBob25lIjoiMTc2MDE0NDQ3NjgiLCJsb2dpbl90aW1lIjoxNjcyMzA0MzExMDQ3LCJhcHBfdXNlcl9rZXkiOiIxNjsxNzYwMTQ0NDc2OCJ9.42dRkMEJqUR3sSfoOnOcpzWdtGw3qXDrZB1EdV_lzR0eE-FZCvicwj52T5FvUQw7tE3KlRALxa-tlYYfs-ThAQ"

            } catch (e) {
                console.log("token错误");
                navigate("/err")
            }
        }).then()
    })
    return <>
        <Result status='waiting'
                title='正在加载，请稍后...'/>
    </>
}

export default Load